export const environment = {
  production: true,

  apiUrl: 'https://devapi.originpersia.com',
  vendorUrl: 'https://devvendor.originpersia.com/auth',
  adminUrl: 'https://devAdmin.originpersia.com/auth',
  customerUrl: 'https://devcustomer.originpersia.com/auth',

  // apiUrl: 'https://api.originpersia.com',
  // vendorUrl: 'https://vendor.originpersia.com/auth',
  // adminUrl: 'https://Admin.originpersia.com/auth',
  // customerUrl: 'https://customer.originpersia.com/auth',

  // apiUrl: 'https://api.vtrin.ae',
  // vendorUrl: 'https://vendor.vtrin.ae/auth',
  // adminUrl: 'https://Admin.vtrin.ae/auth',
  // customerUrl: 'https://customer.vtrin.ae/auth',

  captchaKey: '6Lco11UgAAAAAKY_9iOdioX0Uywo1DGQoE3pYagH',
};
