<header
  data-sticky="true"
  class="header d-flex flex-column"
  *ngIf="searchTitles && headerTitles"
  [ngClass]="direction === 'rtl' ? 'rtl' : 'ltr'"
  [dir]="direction === 'rtl' ? 'rtl' : 'ltr'"
  (mouseleave)="isQuickLoginOpen = false"
>
  <button class="btn persian-blue white fs-14 radius-none" *ngIf="isAdmin" (click)="goToAdmin()">
    {{ headerTitles.admin_panel }}
  </button>
  <div class="container d-none d-lg-flex flex-row align-items-center py-3 px-0">
<!--    <a *ngIf="language === 'fa'" class="logo img-fluid" [routerLink]="'/'"><img src="../../../assets/images/OriginPersia Logo1.PNG" alt="OriginPersia.com" title="" /></a>-->
<!--    <a *ngIf="language !== 'fa'" class="logo img-fluid" [routerLink]="'/'"><img src="../../../assets/images/nonPersianLogo.png" alt="OriginPersia.com" title="" /></a>-->
<!--    <a class="logo img-fluid" [routerLink]="'/'"><img src="../../../assets/images/VTRIN-AE-LOGO.png" alt="vtrin.ae" title="" /></a>-->
    <a class="logo img-fluid" [routerLink]="'/'"><img src="../../../assets/images/nonPersianLogo.png" alt="OriginPersia.com" title="" /></a>
    <form class="d-flex flex-row flex-grow-1 search-box border border-radius margin position-relative" autocomplete="off" >
      <input autocomplete="false" name="hidden" type="text" style="display: none">
      <div class="d-flex flex-row align-items-center bg-light-gray white h-100 position-relative px-3 dropdown-box">
        <select
          id="search-type-dropdown"
          (change)="searchTypeChanged($event)"
          class="form-control bg-transparent search-type pointer"
          [(ngModel)]="searchType"
          [ngModelOptions]="{ standalone: true }"
        >
          <option class="medium-gray" value="product" selected>{{ searchTitles.product }}</option>
          <option class="medium-gray" value="vendor" >{{ searchTitles.vendor }}</option>
        </select>
        <img src="../../../assets/icons/arrow-down.svg" class="arrow" alt="arrow down" />
      </div>
      <input
        type="text"
        class="form-control"
        (keyup.enter)="onSearch()"
        (blur)="hide()"
        autocomplete="off"
        [placeholder]="searchType !== 'product' ? searchTitles.vendor_search : searchTitles.product_search"
        [formControl]="search"
      />
      <div class="position-absolute search-result-box shadow-sm border border-radius invisible" id="search-result-box">
        <div *ngIf="searchType === 'product'">
          <div class="w-100 d-flex flex-column" *ngFor="let product of products; let i = index">
            <app-product-card-search [product]="product"></app-product-card-search>
            <hr *ngIf="i !== products.length - 1" class="mx-2" />
          </div>
        </div>
        <div *ngIf="searchType === 'vendor'">
          <div class="w-100 d-flex flex-column" *ngFor="let vendor of vendors; let i = index">
            <app-vendor-card-search [vendor]="vendor"></app-vendor-card-search>
            <hr *ngIf="i !== vendors.length - 1" class="mx-2" />
          </div>
        </div>
      </div>
      <div class="p-2 pointer" (click)="onSearch()">
        <img src="../../../assets/icons/search.svg" alt="search" />
      </div>
    </form>
    <div
      *ngIf="!isLoggedIn; else loggedIn"
      class="border border-radius account d-flex flex-row align-items-center p-2 margin position-relative"
    >
      <div class="d-flex flex-row align-items-center pointer" (mouseenter)="showQuickView()">
        <img src="../../../assets/icons/login.svg" alt="login" />
        <a class="fs-16 bold" [routerLink]="[language, 'account', 'login']" (click)="hideQuickViewButton()">{{
          headerTitles.login
        }}</a>
      </div>
      <div class="vertical-line mx-2"></div>
      <a class="pointer fs-16 bold" [routerLink]="[language, 'account', 'register']" (click)="hideQuickViewButton()">{{
        headerTitles.register
      }}</a>
      <app-quick-login id="quick-login" class="invisible" (mouseleave)="hideQuickView($event)"></app-quick-login>
    </div>
    <ng-template #loggedIn>
      <div class="border border-radius account d-flex flex-row align-items-center p-2 margin">
        <div class="d-flex flex-row align-items-center">
          <img src="../../../assets/icons/profile.svg" alt="profile" />
          <a class="fs-16 bold" [routerLink]="[language, 'dashboard']">{{ fullName === 'null null' || fullName === '' ? apiTitles.defaultName : fullName }}</a>
          <span class="red margin pointer" (click)="logout()">{{ headerTitles.exit }}</span>
        </div>
      </div>
    </ng-template>
  </div>

  <!-- ==================== mobile header ======================= -->
  <app-mobile-header
    [isLoggedIn]="isLoggedIn"
    [categories]="categories"
    [language]="language"
    [direction]="direction"
    [logo]="logo"
  >
  </app-mobile-header>
</header>
