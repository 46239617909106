<!-- ==================== mobile header ======================= -->
<div
  class="mobile-header shadow-sm"
  [ngClass]="direction === 'rtl' ? 'rtl' : 'ltr'"
  [dir]="direction === 'rtl' ? 'rtl' : 'ltr'"
>
  <div class="container d-flex d-lg-none align-items-center justify-content-between py-2">
    <div class="menu-button" (click)="onMenuIcon()">
      <img src="../../../../assets/icons/open-menu.svg" title="" alt="" />
    </div>
    <div class="mobile-logo">
<!--      <img *ngIf="language === 'fa'" class="logo img-fluid" [routerLink]="'/'" src="../../../../assets/images/OriginPersia Logo1.PNG" alt="OriginPersia.com" title="" />-->
<!--      <img *ngIf="language !== 'fa'" class="logo img-fluid" [routerLink]="'/'" src="../../../../assets/images/nonPersianLogo.png" alt="OriginPersia.com" title="" />-->
<!--      <a class="logo img-fluid" [routerLink]="'/'"><img src="../../../assets/images/VTRIN-AE-LOGO.png" alt="vtrin.ae" title="" /></a>-->
      <img class="logo img-fluid" [routerLink]="'/'" src="../../../../assets/images/nonPersianLogo.png" alt="OriginPersia.com" title="" />
    </div>
    <div class="d-flex">
      <div class="search-icon" (click)="onSearchIcon()" [ngClass]="direction === 'rtl' ? 'ms-2' : 'me-2'">
        <img src="../../../../assets/icons/search-icon.svg" title="" alt="" />
      </div>
      <div class="user-icon" *ngIf="!isLoggedIn" (click)="onLoginIcon()">
        <img src="../../../../assets/icons/user-icon.svg" title="" alt="" />
      </div>
      <div class="user-icon" *ngIf="isLoggedIn" (click)="onUserIcon()">
        <img src="../../../../assets/icons/user-icon.svg" title="" alt="" />
      </div>
    </div>
  </div>

  <aside class="mobile-sidebar invisible d-lg-none" id="mobile-sidebar" (click)="onMenuIcon()">
    <ul class="mobile-sidebar-menu" (click)="$event.stopPropagation()">
      <li class="menu-brand">
<!--        <img [routerLink]="'/'" src="../../../../assets/images/logo.png" alt="" />-->
        <img *ngIf="language === 'fa'" class="sidebar-logo img-fluid" [routerLink]="'/'" src="../../../../assets/images/OriginPersia Logo1.PNG" alt="logo" />
        <img *ngIf="language !== 'fa'" class="sidebar-logo img-fluid" [routerLink]="'/'" src="../../../../assets/images/nonPersianLogo.png" alt="logo" />
      </li>
      <li class="mobile-sidebar-menu-item align-items-start">
        <img src="../../../../assets/icons/list-detail.svg" alt="" [ngClass]="direction === 'rtl' ? 'ms-1' : 'me-1'" />

        <div class="accordion accordion-flush w-100" id="accordionFlushExample">
          <div class="accordion-item">
            <div class="mb-0 d-flex" id="flush-headingOne">
              <button
                class="collapsed mobile-sidebar-menu-link accordion-button p-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                {{ categoriesMenuTitle.shop_by_department }}
              </button>
            </div>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body px-0">
                <ul class="p-0 m-0 d-flex flex-column list-unstyled">
                  <li *ngFor="let category of categories; let i = index" class="mobile-submenu-item">
                    <div class="d-flex flex-column">
                      <div class="d-flex align-items-center">
                        <i class="fontello" *ngIf="category.CssClass" [ngClass]="category.CssClass"></i>
                        <span
                          class="mobile-sidebar-menu-link"
                          *ngIf="category.Childs.length === 0"
                          [ngClass]="direction === 'rtl' ? 'me-1' : 'ms-1'"
                          (click)="goToProductSearch(category)"
                          >{{ category.currentName }}</span
                        >
                        <div
                          class="d-flex w-100 justify-content-between"
                          *ngIf="category.Childs.length !== 0"
                          [ngClass]="direction === 'rtl' ? 'rtl' : 'ltr'"
                          (click)="onOpenAccordion(i)"
                        >
                          <a class="mobile-sidebar-menu-link text-justify text-truncate">
                            {{ category.currentName }}
                          </a>
                          <img src="../../../../assets/icons/cherven-down.svg" alt="" *ngIf="i !== openedItemIndex" />
                          <img src="../../../../assets/icons/cherven-up.svg" alt="" *ngIf="i === openedItemIndex" />
                        </div>
                      </div>
                      <div>
                        <app-accordion
                          (menuOpen)="onMenuIcon()"
                          *ngIf="i === openedItemIndex"
                          [category]="category"
                        ></app-accordion>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
<!--      <li class="mobile-sidebar-menu-item">-->
<!--        <img src="../../../../assets/icons/discount.svg" [ngClass]="direction === 'rtl' ? 'ms-1' : 'me-1'" alt="" />-->
<!--        <a class="mobile-sidebar-menu-link" (click)="onMenuIcon()" [routerLink]="[language, 'shipping']">{{-->
<!--          menuTitles.product_sale-->
<!--        }}</a>-->
<!--      </li>-->
      <li class="mobile-sidebar-menu-item">
        <img src="../../../../assets/icons/truck.svg" [ngClass]="direction === 'rtl' ? 'ms-1' : 'me-1'" alt="" />
        <a class="mobile-sidebar-menu-link" (click)="onMenuIcon()" [routerLink]="[language, 'public', 'transport']">{{
          menuTitles.shipping
        }}</a>
      </li>
      <li class="mobile-sidebar-menu-item">
        <img src="../../../../assets/icons/businessplan.svg" [ngClass]="direction === 'rtl' ? 'ms-1' : 'me-1'" alt="" />
        <a class="mobile-sidebar-menu-link" (click)="onMenuIcon()" [routerLink]="[language, 'public', 'customs']">{{
          menuTitles.customs_services
        }}</a>
      </li>
      <li class="mobile-sidebar-menu-item">
        <img src="../../../../assets/icons/creditcard.svg" [ngClass]="direction === 'rtl' ? 'ms-1' : 'me-1'" alt="" />
        <a class="mobile-sidebar-menu-link" (click)="onMenuIcon()" [routerLink]="[language, 'public', 'bank-credit']">{{
          menuTitles.bankCredit
        }}</a>
      </li>
      <li class="mobile-sidebar-menu-item">
        <img src="../../../../assets/icons/shield.svg" [ngClass]="direction === 'rtl' ? 'ms-1' : 'me-1'" alt="" />
        <a class="mobile-sidebar-menu-link" (click)="onMenuIcon()" [routerLink]="[language, 'public', 'insurance']">{{
          menuTitles.insurance
        }}</a>
      </li>
      <li class="mobile-sidebar-menu-item">
        <img src="../../../../assets/icons/monitor.svg" width="20" height="20" [ngClass]="direction === 'rtl' ? 'ms-1' : 'me-1'" alt="" />
        <a class="mobile-sidebar-menu-link" (click)="onMenuIcon()" [routerLink]="[language, 'tv']">{{
          menuTitles.tv
          }}</a>
      </li>
      <li class="mobile-sidebar-menu-item" *ngIf="isLoggedIn">
        <img src="../../../../assets/icons/logout.svg" alt="" [ngClass]="direction === 'rtl' ? 'ms-1' : 'me-1'" />
        <a class="mobile-sidebar-menu-link text-danger" (click)="signOut()">{{headerTitles.exit}}</a>
      </li>
    </ul>
  </aside>

  <div class="mobile-search-box invisible d-lg-none" id="search-box-mobile">
    <form class="d-flex flex-column" (ngSubmit)="onSearch()" >
      <div class="close-icon" (click)="onSearchIcon()">
        <img src="../../../../assets/icons/close-icon.svg" title="" alt="" />
      </div>
      <select
        class="form-control bg-white mb-3 search-type pointer"
        [(ngModel)]="searchType"
        [ngModelOptions]="{ standalone: true }"
      >
        <option value="product" selected>{{ searchTitles.product }}</option>
        <option value="vendor">{{ searchTitles.vendor }}</option>
      </select>
      <div class="search-input d-flex justify-content-between align-items-center">
        <input
          type="text"
          class="form-control"
          (keydown.enter)="onSearch()"
          [placeholder]="searchType !== 'product' ? searchTitles.vendor_search : searchTitles.product_search"
          [formControl]="search"
        />
        <ng-container *ngIf="direction === 'rtl'">
          <div (click)="onSearch()" style="position: absolute; left: 20px; margin-bottom: 3px">
            <img height="20" src="../../../../assets/icons/search-icon.svg" title="" alt="" />
          </div>
        </ng-container>
        <ng-container *ngIf="direction === 'ltr'">
          <div (click)="onSearch()" style="position: absolute; right: 20px; margin-bottom: 3px">
            <img height="20" src="../../../../assets/icons/search-icon.svg" title="" alt="" />
          </div>
        </ng-container>
      </div>
    </form>
  </div>

  <div class="mobile-user-dropdown d-lg-none" *ngIf="isLoginContext" id="mobile-user-dropdown">
    <div class="user-dropdown-context">
      <a
        class="user-dropdown-context-link"
        (click)="isLoginContext = !isLoginContext"
        [routerLink]="[language, 'account', 'login']"
        >{{ headerTitles.login }}</a
      >
      <hr class="horizontal-line" />
      <a
        class="user-dropdown-context-link"
        (click)="isLoginContext = !isLoginContext"
        [routerLink]="[language, 'account', 'register']"
        >{{ headerTitles.register }}</a
      >
    </div>
  </div>
</div>
